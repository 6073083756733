export const newsList = [
  {
    id: 309,
    src: require('../../assets/images/mainPageImages/newsImages/news-309.jpg'),
    title: 'День защиты детей',
    description: '1 июня в селе «Былово» вблизи дома культуры «Юбилейный»  была проведена развлекательная программа, приуроченная международному празднику «Дню защиты детей». Ребята приняли участие в различных эстафетах и активностях. Всем участникам раздавали воздушные шарики, мороженное и море незабываемых эмоций и впечатлений.'
  },
  {
    id: 308,
    src: require('../../assets/images/mainPageImages/newsImages/news-308.jpg'),
    title: 'Модное детство',
    description: '1 июня состоялся конкурс-дефиле "Модное детство". Данный конкурс дает возможность развиваться, учиться новому, покорять новые вершины. Шахматы и плавание, лего и танцы, брейкинг и вокал, рисование и игра на барабанах - и это только часть увлечений наших талантливых ребят. Каждый, кто выходил  на сцену, старался показать свои умения. Жюри невероятно сложно было выбирать лучших. Поздравляем всех с праздником и желаем нашей детворе и дальше радовать родителей и друзей своими талантами и успехами!  Пусть каждый ребёнок чувствует себя защищённым и любимым! Окружайте ваших детей любовью каждый день!'
  },
  {
    id: 307,
    src: require('../../assets/images/mainPageImages/newsImages/news-307.jpg'),
    title: 'День соседей',
    description: 'Яркая концертная программа и мастер-классы к Международному дню соседей прошли в селе Былово. Шахматные турниры, дартс и другие спортивные соревнования провели представители СК Олимп, разнообразные угощения привезли для гостей члены Совета ветеранов, ухой в казане потчевали боевые братья Краснопахорского. Поздравил жителей глава поселения Игорь Лебедев. — Также хочется отметить старания молодых парламентариев которые организовали спортивно-развлекательную программу для детей и подростков, — отметила после завершения мероприятия Ольга Гущина, муниципальный депутат поселения.Председатель Общественного совета многодетных родителей Мадина Романчева провела зажигательные интерактивы, а любители живописи смогли насладиться художественной выставкой работ краснопахорцев, расположившейся традиционно в аллее у открытой сцены ДК.'
  },
  {
    id: 306,
    src: require('../../assets/images/mainPageImages/newsImages/news-306.jpg'),
    title: 'Искусство - любовь моя!',
    description: 'Фестиваль «Искусство - любовь моя!» состоялся в Московском государственном академическом детском музыкальном театре имени Н. И. Сац. Он был посвящен году семьи и 20-летию ассоциации школьных театров. Наши ведущие творческие коллективы города Москвы «Арлекин» дома культуры «Юбилейный» и «Сорванцы» дома культуры «Звездный» приняли активное участие в фестивале, выступив со своими лучшими театральными постановками.'
  },
  {
    id: 305,
    src: require('../../assets/images/mainPageImages/newsImages/news-305.jpg'),
    title: 'Всероссийский день книг',
    description: 'Сегодня, 27 мая, в нашей стране особое событие – Всероссийский день книг. В Краснопахорском поселении действуют 4 библиотеки, библиотечный фонд которых насчитывает более 64 000 экземпляров книг и периодических изданий. Это огромная цифра по меркам не только столицы, но и России. Несомненно, каждая книга требует трепетного обращения человека. Такими «хранителями» являются библиотекари - служители книжного мира, в чьих руках каждая страница книги оживает и играет самыми яркими красками, даря чувство волшебства. Я от всей души поздравляю с праздником не только читателей, но и наших библиотекарей: Людмилу Абрамову, Светлану Мочалову, Ольгу Горячкину и Наталью Иванову с Всероссийским днем библиотек! Желаю вам быть преданными профессии и так же приобщать москвичей и гостей столице к культуре! Спасибо за ваш труд и любовь к литературе!'
  },
  {
    id: 304,
    src: require('../../assets/images/mainPageImages/newsImages/news-304.jpg'),
    title: 'Память поколений - 1812',
    description: 'Продолжаются занятия в рамках военно-исторических сборов «Память поколений - 1812». Герои на Руси были и есть всегда, и чтобы проследить связь времен, темой сегодняшнего дня стала - Великая Отечественная война 1941-1945гг., а точнее - Битва за Москву. Ждем всех желающих присоединиться к нашему лагерю в парке «Редут 1812 года»! Начало занятий запланировано на 15:00'
  },
  {
    id: 303,
    src: require('../../assets/images/mainPageImages/newsImages/news-303.jpg'),
    title: 'Хорошая новость',
    description: 'Хочу поделиться с вами хорошей новостью! В начале весны ко мне обращались родители участников историко-патриотического клуба «На рубеже» по развитию парка «Редут 1812 года». В марте этого года я инициировала вопрос по цветочному оформлению клумб с дальнейшей передачей предложения в администрацию поселения. Ответ был получен незамедлительно - цветочному оформлению быть! На днях осмотрела ход работ по озеленению. Сделана цветочная клумба и высажены различные цветы и кустовые деревья вблизи центрального входа в парк. Буду держать вас в курсе всех новостей!'
  },
  {
    id: 302,
    src: require('../../assets/images/mainPageImages/newsImages/news-302.jpg'),
    title: 'Игротека',
    description: 'В доме культуры «Юбилейный» села Былово состоялась игротека. Дети с удовольствием поиграли в разные настольные игры, среди которых «Лото», «Морской бой» и «Монополия». «Сейчас среди родителей и детей очень популярны настольные игры. Во-первых, это заинтересовывает детвору, а во-вторых, развивает разные полушария мозга. Конечно, и взрослым приятно проводить время со своими детьми с пользой», - отметила мама одного из «игроков» Ирина Седунова'
  },
  {
    id: 301,
    src: require('../../assets/images/mainPageImages/newsImages/news-301.jpg'),
    title: '«Терроризм сжигает мир!»',
    description: 'Состоялся лекторий в клубе «Эрудит» на тему «Терроризм сжигает мир!». «Каждый раз на наших встречах мы рассказываем детям о необходимости во всем соблюдать законы, быть внимательными в повседневной жизни. Поговорили о том, что терроризм - это аморальное явление. Дети у нас воспитанные и умные, а потому понимают, что позволительно, а что запрещено в современном мире», - отметила заместитель директора дома культуры «Звездный» Елена Михайлина.'
  },
  {
    id: 300,
    src: require('../../assets/images/mainPageImages/newsImages/news-300.jpg'),
    title: 'Фестиваль - конкурс циркового любительского искусства',
    description: '21 апреля, в нашем доме культуры прошел первый фестиваль - конкурс циркового любительского искусства «Время цирка». Потрясающие номера, яркие костюмы, участники приехали из столицы и области. Гран - При фестиваля в этот раз уехало в ГБУ ДО города Москвы ДШИ им. М.А. Балакирева, с коллективом «Дети Олимпа». Отдельно поздравляю участников нашего циркового коллектива «На бис!»: Дипломант 3 степени Сырчина Полина, Лауреат 3 степени Прохорова Софья, Лауреатами 1 степени стали Ялангова Яна, Петрова Кристина, Зайцева Анна, Иналова Лина, Шамарина Валерия. Всем участникам огромное спасибо, это был настоящий праздник!'
  },

]

export const moscowEvents = [
    {
      id: 2,
      type: 'Интерактивная, Классическое искусство',
      name: 'Айвазовский. Кандинский. Живые полотна',
      place: 'Пространство «Люмьер-холл»',
      date: 'до 23 апреля 2023',
      description: 'На выставке представлено более 100 работ Айвазовского из музеев по всей России, большинство из них сделано в формате ps-3D, что позволяет посетителям почувствовать себя внутри картины. А мощные проекторы высокого разрешения дополненные объемным звуком перенесут посетителей за тысячи километров в акваторию Черного моря на место известных корабельных сражений.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-2.jpg'),
      to: 'https://www.afisha.ru/exhibition/258674/'
    },
    {
      id: 3,
      type: 'Выставка в Москве',
      name: 'Первые моторы России',
      place: 'Музей гаража особого назначения ФСО России',
      date: 'до 27 октября 2023',
      description: 'О первых страницах истории русского автомобилизма — зарождении технической культуры, творчества, инженерной школы — расскажет выставка «Первые моторы России» в Музее Гаража особого назначения на ВДНХ.Павильон 54 музея превращен в зал IV Международной автомобильной выставки. В исторических интерьерах представлены уникальные экспонаты из коллекции Политехнического музея, находившиеся в запасниках и впервые собранные вместе.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-3.jpeg'),
      to: 'https://www.afisha.ru/exhibition/272590/'
    },
    {
      id: 4,
      type: 'ВЫСТАВКА В МОСКВЕ',
      name: 'Космонавтика и авиация',
      place: 'Павильон «Космос»',
      date: 'Постоянная экспозиция',
      description: 'В основной экспозиции — огромный архив документальных свидетельств покорения космоса: 120 образцов одной только техники и больше 2 тысяч разных документов, включая фото и видео. Под куполом расположен 5D-кинотеатр, который рассказывает о Большом взрыве и светлом будущем с колонией на Марсе.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-4.jpg'),
      to: 'https://www.afisha.ru/exhibition/208082/'
    }
]